<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/first-time-home-buyer">First Time Home Buyers</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">

              <h2 class="mb-3">Terms</h2>
              <p class="lead">Depending on the type of transfer you are registering, you may not have to complete this section. If required, you have to provide detailed financial information for the purchase/transfer.</p>

              <div class="mb-4 bg-medium">
                <form v-on:submit.prevent>
                  <div class="bg-medium-dark py-1 px-3">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-8">
                        <label for="funds"><strong>Funds (Cash) </strong> <small>(optional)</small></label>
                      </div>
                      <div class="col-4">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="text" class="form-control text-right border-left-0" id="funds">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mb-1 pt-2">
                    <div class="col-5">
                      <label class="pl-3" for="canadianSources"><small>Canadian Sources (optional)</small></label>
                    </div>
                    <div class="col-3">
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control form-control-sm text-right border-left-0" id="canadianSources">
                      </div>
                    </div>
                  </div>

                  <div class="form-row pb-2">
                    <div class="col-5">
                      <label class="pl-3" for="otherSources">
                        <small>Other Sources (optional)</small>
                        <a href="#" data-toggle="popover" data-placement="top" data-content="Funding from sources outside of Canada." v-popover>
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        </a>
                      </label>
                    </div>
                    <div class="col-3">
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input type="text" class="form-control form-control-sm text-right border-left-0" id="otherSources">
                      </div>
                    </div>
                  </div>

                  <div class="bg-medium-dark py-1 px-3">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-8">
                        <label for="financing"><strong>Financing</strong> <small>(optional)</small></label>
                      </div>
                      <div class="col-4">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="text" class="form-control text-right border-left-0" id="financing">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mb-1 pt-2 px-3">
                    <div class="col-5">
                      <label for="lenderName"><small>Lender Name (optional)</small></label>
                    </div>
                    <div class="col-7">
                      <input type="text" class="form-control form-control-sm text-right" id="lenderName">
                    </div>
                  </div>

                  <div class="form-row pb-2 px-3">
                    <div class="col-5">
                      <label for="branchNumber"><small>Branch Number (optional)</small></label>
                    </div>
                    <div class="col-7">
                      <input type="text" class="form-control form-control-sm text-right" id="branchNumber">
                    </div>
                  </div>

                  <div class="bg-medium-dark py-1 px-3">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-8">
                        <label for="otherConsiderations"><strong>Other consideration paid or property taken in trade</strong> <small>(optional)</small></label>
                      </div>
                      <div class="col-4">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="text" class="form-control text-right border-left-0" id="otherConsiderations">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-0 py-2 px-3">
                      <label for="details"><small>Details (optional)</small></label>
                      <textarea class="form-control" name="details" id="details" cols="30" rows="3"></textarea>
                  </div>

                  <div class="bg-medium-dark py-2 px-3">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-8">
                        <h4 class="mb-0">Gross Purchase Price</h4>
                        <p class="mb-0">Total of Funds, Financing, and Other</p>
                      </div>
                      <h2 class="col-4 mb-0 text-right">$2,000,000</h2>
                    </div>
                  </div>

                  <div class="p-3">
                    <label class="mt-2">Do the terms of this transaction include property taken in trade? (optional)</label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="propertyTaken" name="propertyTaken" class="custom-control-input" checked>
                        <label class="custom-control-label" for="propertyTaken">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="propertyTaken2" name="propertyTaken" class="custom-control-input">
                        <label class="custom-control-label" for="propertyTaken2">No</label>
                      </div>
                    </div>

                    <div class="mt-3">
                      <h4>Identify the property by providing the following:</h4>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="address1">Address 1</label>
                          <input type="text" class="form-control" id="address1">
                          <small class="form-text text-muted">Street No./Name</small>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="address2">Address 2 <small>(optional)</small></label>
                          <input type="text" class="form-control" id="address2">
                          <small class="form-text text-muted">Apt. No., PO Box, RR</small>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="city">City</label>
                          <input type="text" class="form-control" id="city">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="country">Country</label>
                          <select class="form-control" id="country">
                            <option>Canada</option>
                            <option>United Kingdom</option>
                            <option>United States</option>
                            <option>Mexico</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-8 col-md-6">
                          <label for="province">Province</label>
                          <select class="form-control" id="province">
                            <option value="AB">Alberta</option>
                            <option value="BC" selected>British Columbia</option>
                            <option value="MB">Manitoba</option>
                            <option value="NB">New Brunswick</option>
                            <option value="NL">Newfoundland and Labrador</option>
                            <option value="NT">Northwest Territory</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="NU">Nunavut</option>
                            <option value="ON">Ontario</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatchewan</option>
                            <option value="YT">Yukon Territory</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-4 col-md-6">
                          <label for="postalCode">
                            Postal/ZIP Code
                            <a href="#" data-toggle="popover" data-placement="top" data-content="If you do not know the postal code please use UNK for Canada or NCA otherwise." v-popover>
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                            </a>
                          </label>
                          <input class="form-control col-md-6" name="ship-zip" id="postalCode" autocomplete="shipping postal-code">
                        </div>
                      </div>

                      <description-of-land></description-of-land>
                    </div>
                  </div>

                </form>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../../components/PackageHeader.vue'
import PackageNavigation from '../../../../components/PackageNavigation.vue'
import HelpPanel from '../../../../components/HelpPanel.vue'
import DescriptionOfLand from './DescriptionOfLand.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
    'description-of-land': DescriptionOfLand
  },

  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

