<template>
  <div>

    <!-- Existing Related PIDs or plan numbers -->
    <div class="mt-3">
      <div class="d-flex justify-content-between">
        <h3 class="mb-0">Descriptions of Land <i>({{ pids.length }})</i></h3>
        <button class="btn btn-tertiary btn-sm" @click="formOpen = !formOpen" v-if="!formOpen">Add New</button>
      </div>

      <!-- Add new PID form -->
      <div class="mt-3 mb-4" v-if="formOpen">
        <div class="form-group">
          <label for="">Enter PID or Related Plan Number</label>
          <input class="form-control col-sm-6" type="text" v-model="filingNumber">
          <small class="form-text text-muted">E.g. 1234567 or 001-234-567</small>
        </div>
        <button class="btn btn-md btn-tertiary" @click.prevent="addRow(filingNumber)">Add to List</button>
        <button class="btn btn-md btn-link" @click="formOpen = false">Cancel</button>
      </div>

      <table class="mt-2 table table-stacked table-select-row bg-white">
        <thead>
          <tr>
            <th class="text-nowrap" scope="col">PID/Plan Number</th>
            <th scope="col">Parcel Info</th>
            <th scope="col" class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pid, index) in pids" :key="index">
            <td scope="col" data-header="PID/Plan Number">
              <span class="td-content">{{ pid.number }}</span>
            </td>
            <td scope="col" data-header="Legal Description">
              <dl v-if="pid.editing">
                <dt>Legal Description</dt>
                <dd>
                  <textarea class="form-control form-control-sm" type="text">LOT 4 DISTRICT LOT 261 GROUP 1 NEW WESTMINSTER DISTRICT PLAN LMP 31075</textarea>
                </dd>
                <dt>Municipality</dt>
                <dd>
                  <select class="form-control form-control-sm" name="regionalDistrict" id="regionalDistrict">
                    <option value="">RSummerland, The Corporation of the District of</option>
                  </select>
                </dd>
                <dt>Regional District</dt>
                <dd>
                  <select class="form-control form-control-sm" name="regionalDistrict" id="regionalDistrict">
                    <option value="">Regional District of Okanagan-Similkameen</option>
                  </select>
                </dd>
              </dl>
              <dl v-else>
                <dt>Legal Description</dt>
                <dd>
                  LOT 4 DISTRICT LOT 261 GROUP 1 NEW WESTMINSTER DISTRICT PLAN LMP 31075
                </dd>
                <dt>Municipality</dt>
                <dd>
                  Summerland, The Corporation of the District of
                </dd>
                <dt>Regional District</dt>
                <dd>
                  Regional District of Okanagan-Similkameen
                </dd>
              </dl>
              <div v-if="pid.editing">
                <button class="btn btn-sm btn-tertiary" @click="pids[index].editing = false">Done</button>
                <button class="btn btn-sm btn-link" @click="pids[index].editing = false">Cancel</button>
              </div>
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-link pr-0" @click="editPid(index)" v-if="!pid.editing">Edit</button>
              <button class="btn btn-sm btn-link text-danger pr-0" @click="removeRow(index)">Remove</button>
            </td>
          </tr>
          <tr v-if="pids.length == 0">
            <td colspan="3">
              <div class="d-flex">
                <svg class="icon icon-alert icon-lg mr-1 text-info"><use xlink:href="/icons/symbol-defs.svg#icon-alert"></use></svg>
                <p>You currently do not have any Descriptions of Land. You can add a new Description of Land by clicking on Add New.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formOpen: false,
        filingNumber: null,
        pids: []
      }
    },

    methods: {
      addRow(number) {
        this.pids.push({number: this.filingNumber, editing: false})
        this.formOpen = false
      },

      removeRow(index) {
        this.pids.splice(index, 1)
      },

      editPid(index) {
        this.pids[index].editing = true;
      }
    }
  }
</script>
